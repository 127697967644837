import { DimmeableImageProps } from "elements/DimmeableImage/typing";
import * as React from "react";
import Dimmer from "semantic-ui-react/dist/commonjs/modules/Dimmer";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";

export class DimmeableImage extends React.PureComponent<DimmeableImageProps> {
	state = {
		active: false,
	};

	handleShow = () => this.setState({ active: true });
	handleHide = () => this.setState({ active: false });

	render() {
		const { active } = this.state;
		const { content, size, src, className = "" } = this.props;

		return (
			<Dimmer.Dimmable
				className={`DimmeableImage ${className}`}
				as={Image}
				dimmed={active}
				dimmer={{
					active,
					content,
				}}
				onMouseEnter={this.handleShow}
				onMouseLeave={this.handleHide}
				size={size}
				src={src}
			/>
		);
	}
}
