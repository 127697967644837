import { Translation } from "i18n/config";
import { MarketplaceOfferType, MarketplaceType } from "models/marketplaces/Marketplace";
import { NewMarketplaceTranslation } from "./typing";

export const NewMarketplaceTexts: Translation<NewMarketplaceTranslation> = {
	es: {
		missingDescriptionShipping: "Métodos de entrega no especificados",
		missingDescriptionWarranty: "Garantía no especificada",
		missingDescriptionWhatDoYouOffer: "No se ha especificado que recibes si validas el producto/servicio",
		missingDescription: "Descripción general faltante",
		tryAgainMessage: "Ha ocurrido un error, intentelo de nuevo mas tarde.",
		type: (type: MarketplaceType) => {
			let text = type as string;

			switch (type) {
				case MarketplaceType.PRODUCT:
					text = "Producto";
					break;
				case MarketplaceType.SERVICE:
					text = "Servicio";
					break;
			}

			return text;
		},
		offerType: (offerType: MarketplaceOfferType) => {
			let text = offerType as string;

			switch (offerType) {
				case MarketplaceOfferType.SELL:
					text = "Vender";
					break;
				case MarketplaceOfferType.VALIDATE:
					text = "Validar";
					break;
			}

			return text;
		},
		typeFieldTitle: "Producto o Servicio",
		typeFieldTitlePlaceholder: "Elije producto o servicio",
		offerTypeFieldTitle: "Tipo de oferta",
		offerTypeFieldPlaceholder: "Identifica tu oferta",
		titleCreate: () => "Crear nueva oferta de Marketplace",
		titleUpdate: (title: string) => `Actualizar "${title}"`,
		title: "Título",
		placeholderTitle: "Título de tu oferta",
		slug: "Link con el cual quieres acceder a este Marketplace dentro de Vinglet",
		email: "¿Qué correo electrónico le gustaría usar para obtener información actualizada sobre este elearning?",
		url: "Copia y pega el enlace del producto o servicio que quieres validar o vender",
		validationURL: "Copia y pega el enlace de tu formulario interactivo para validar producto o servicio",
		start: "Comienzo",
		from: "Desde",
		end: "Fin",
		to: "Hasta",
		backgroundImage: "Imagenes",
		backgroundImagePlaceholder: "Adjunta un máximo de 5 fotográficas",
		changeBackgroundImage: "Cambiar Imagen",
		description: "Descripción",
		descriptionWarranty: "Garantías",
		descriptionWarrantyPlaceholder: "",
		descriptionShipping: "Formas de entrega",
		descriptionWhatDoYouOffer: "¿Qué ofreces a cambio de que te validen?",
		descriptionWhatDoIOffer: "¿Qué ofrezco a cambio de que me validen?",
		regions: "Regiones donde vendes tu producto o servicio",
		regionsPlaceholder: "Identifica las regiones donde estas ofertando",
		sectors: "Sectores",
		sectorsPlaceholder: "Identifica los sectores a los cuales te diriges",
		noRegions: "No hay Regiones seleccionadas.",
		noSectors: "No hay Sectores seleccionados.",
		language: "Idioma",
		quantity: "Cantidad",
		quantityUnities: "Cuántas unidades",
		tags: "Tags",
		pvp: "PVP en dólar estadunidenses",
		pvpPlaceholder: "Identifica tu precio en USD",
		discount: "Descuento que aplica a las venta",
		discountPlaceholder: "Identifica tu porcentaje",
		checkAllFieldsMessage: "Verifique que todos los campos esten completados.",
		errorMessage: "Ha ocurrido un error al procesar tu petición, intente de nuevo mas tarde.",
		createNew: "Crear oferta",
		response: "Responder cuestionario",
	},
	en: {
		missingDescriptionShipping: "Unspecified delivery methods",
		missingDescriptionWarranty: "Unspecified Warranty",
		missingDescriptionWhatDoYouOffer: "It has not been specified what you receive for validating the product/service",
		missingDescription: "Missing general description",
		tryAgainMessage: "There was an error, try again later",
		type: (type: MarketplaceType) => {
			let text = type as string;

			switch (type) {
				case MarketplaceType.PRODUCT:
					text = "Product";
					break;
				case MarketplaceType.SERVICE:
					text = "Service";
					break;
			}

			return text;
		},
		offerType: (offerType: MarketplaceOfferType) => {
			let text = offerType as string;

			switch (offerType) {
				case MarketplaceOfferType.SELL:
					text = "Sell";
					break;
				case MarketplaceOfferType.VALIDATE:
					text = "Validate";
					break;
			}

			return text;
		},
		titleCreate: () => "Create a new Marketplace's Offer",
		titleUpdate: (title: string) => `Update "${title}"`,
		typeFieldTitle: "Product or Service",
		typeFieldTitlePlaceholder: "Choose product or service",
		offerTypeFieldTitle: "Offer Type",
		offerTypeFieldPlaceholder: "Identify your offer",
		title: "Title",
		placeholderTitle: "Title of your offer",
		slug: "Vinglet's Link for this Marketplace",
		url: "Do you have any other external link for this Marketplace?",
		validationURL: "Copy and paste the link to validate your product or service",
		email: "What email would you like to using to get updated info about this elearning?",
		start: "Start",
		from: "From",
		end: "End",
		to: "To",
		backgroundImage: "Images",
		backgroundImagePlaceholder: "Attach a maximum of 5 photos",
		changeBackgroundImage: "Change Image",
		description: "Description",
		descriptionWarranty: "Warranty",
		descriptionWarrantyPlaceholder: "",
		descriptionShipping: "Delivery methods",
		descriptionWhatDoYouOffer: "What do you offer in exchange of validating it?",
		descriptionWhatDoIOffer: "What do I offer in exchange of validating it?",
		regions: "Regions where you sell your product or service",
		regionsPlaceholder: "Identify the regions where you are bidding",
		sectors: "Sectors",
		sectorsPlaceholder: "Identify the sectors you are targeting",
		noRegions: "There isn't any selected Regions.",
		noSectors: "There isn't any selected Sector.",
		language: "Language",
		quantity: "Quantity",
		quantityUnities: "How many units",
		pvp: "RRP in US dollars",
		pvpPlaceholder: "Identify your price in USD",
		tags: "Tags",
		discount: "Discount that you apply to each sale",
		discountPlaceholder: "Identify your percentage",
		checkAllFieldsMessage: "Check that all fields are completed",
		errorMessage: "We have an error while we were processing your request, try again later",
		createNew: "Create offer",
		response: "Answer questionnaire",
	},
	ca: {
		missingDescriptionShipping: "Mètodes de lliurament no especificats",
		missingDescriptionWarranty: "Garantia no especificada",
		missingDescriptionWhatDoYouOffer: "No s'ha especificat que reps si valides el producte/servei",
		missingDescription: "Descripció general faltant",
		tryAgainMessage: "Hi ha hagut un error, intenteu de nou més tard.",
		type: (type: MarketplaceType) => {
			let text = type as string;

			switch (type) {
				case MarketplaceType.PRODUCT:
					text = "Producte";
					break;
				case MarketplaceType.SERVICE:
					text = "Servei";
					break;
			}

			return text;
		},
		offerType: (offerType: MarketplaceOfferType) => {
			let text = offerType as string;

			switch (offerType) {
				case MarketplaceOfferType.SELL:
					text = "Vendre";
					break;
				case MarketplaceOfferType.VALIDATE:
					text = "Validar";
					break;
			}

			return text;
		},
		typeFieldTitle: "Producte o Servei",
		typeFieldTitlePlaceholder: "Trieu producte o servei",
		offerTypeFieldTitle: "Tipus d'oferta",
		offerTypeFieldPlaceholder: "Identifica la teva oferta",
		titleCreate: () => "Crear una nova oferta de Marketplace",
		titleUpdate: (title: string) => `Actualitzar & quot; ${title} & quot;`,
		title: "Títol",
		placeholderTitle: "Títol de la teva oferta",
		slug: "Link amb el qual vols accedir a aquest Marketplace dins de Vinglet",
		email: "Quin correu electrònic voldríeu utilitzar per obtenir informació actualitzada sobre aquest tema?",
		url: "Introduïu el link del Marketplace",
		validationURL: "Copia i enganxa l'enllaç del producte o servei que vols validar",
		start: "Començament",
		from: "Des de",
		end: "Fi",
		to: "Fins",
		backgroundImage: "Imatge de Fons",
		backgroundImagePlaceholder: "Adjunta un màxim de 5 fotogràfiques",
		changeBackgroundImage: "Canviar Imatge",
		description: "Descripció",
		descriptionWarranty: "Garantia",
		descriptionWarrantyPlaceholder: "",
		descriptionShipping: "Formes de lliurament",
		descriptionWhatDoYouOffer: "Què ofereixes a canvi que et validin?",
		descriptionWhatDoIOffer: "Què ofereixo a canvi que em validin?",
		regions: "Regions on vens el teu producte o servei",
		regionsPlaceholder: "Identifica les regions on aquestes oferint",
		sectors: "Sectors",
		sectorsPlaceholder: "Identifica els sectors als quals et dirigeixes",
		noRegions: "No hi ha Regions seleccionades.",
		noSectors: "No hi ha Sectors seleccionats.",
		language: "Idioma",
		quantity: "Quantitat",
		quantityUnities: "Quantes unitats",
		tags: "Tags",
		pvp: "PVP a dòlar nord-americans",
		pvpPlaceholder: "Identifica el teu preu en USD",
		discount: "Descompte que apliques a les vendes",
		discountPlaceholder: "Identifica el teu percentatge",
		checkAllFieldsMessage: "Verifiqueu que tots els camps estiguin completats.",
		errorMessage: "Hi ha hagut un error en processar la teva petició, proveu de nou més tard.",
		createNew: "Crear oferta",
		response: "Respondre qüestionari",
	},
	pt: {
		missingDescriptionShipping: "Métodos de entrega não especificados",
		missingDescriptionWarranty: "Garantia não especificada",
		missingDescriptionWhatDoYouOffer: "Não foi especificado que você recebe se validar o produto/serviço",
		missingDescription: "Falta descrição geral",
		tryAgainMessage: "Ocorreu um erro, tente novamente mais tarde.",
		type: (type: MarketplaceType) => {
			let text = type as string;

			switch (type) {
				case MarketplaceType.PRODUCT:
					text = "Produtos";
					break;
				case MarketplaceType.SERVICE:
					text = "Serviço";
					break;
			}

			return text;
		},
		offerType: (offerType: MarketplaceOfferType) => {
			let text = offerType as string;

			switch (offerType) {
				case MarketplaceOfferType.SELL:
					text = "Vender";
					break;
				case MarketplaceOfferType.VALIDATE:
					text = "Validar";
					break;
			}

			return text;
		},
		typeFieldTitle: "Produto ou Serviço",
		typeFieldTitlePlaceholder: "Escolha o produto ou serviço",
		offerTypeFieldTitle: "Tipo de oferta",
		offerTypeFieldPlaceholder: "Identifique sua oferta",
		titleCreate: () => "Criar nova oferta do Marketplace",
		titleUpdate: (title: string) => `Atualizar "${title}"`,
		title: "Título",
		placeholderTitle: "Título da sua oferta",
		slug: "Link com o qual você deseja acessar este Marketplace no Vinglet",
		email: "Qual e-mail você gostaria de usar para obter informações atualizadas sobre este e-learning?",
		url: "Copie e cole o link do produto ou serviço que deseja validar ou vender",
		validationURL: "Copie e cole o link do seu formulário interativo para validar o produto ou serviço",
		start: "Começar",
		from: "A partir de",
		end: "Fim",
		to: "Até",
		backgroundImage: "Imagens",
		backgroundImagePlaceholder: "Anexe no máximo 5 fotos",
		changeBackgroundImage: "Alterar imagem",
		description: "Descrição",
		descriptionWarranty: "Garantia",
		descriptionWarrantyPlaceholder: "",
		descriptionShipping: "Métodos de entrega",
		descriptionWhatDoYouOffer: "O que você oferece em troca de ser validado?",
		descriptionWhatDoIOffer: "O que ofereço em troca de ser validado?",
		regions: "Regiões onde você vende seu produto ou serviço",
		regionsPlaceholder: "Identifique as regiões onde você está fazendo lances",
		sectors: "Setores",
		sectorsPlaceholder: "Identifique os setores para os quais você está direcionando",
		noRegions: "Nenhuma região selecionada.",
		noSectors: "Não há setores selecionados.",
		language: "Idioma",
		quantity: "Quantia",
		quantityUnities: "Quantas unidades",
		tags: "Tags",
		pvp: "PVP em dólares americanos",
		pvpPlaceholder: "Identifique seu preço em USD",
		discount: "Desconto que se aplica às vendas",
		discountPlaceholder: "Identifique sua porcentagem",
		checkAllFieldsMessage: "Verifique se todos os campos estão preenchidos.",
		errorMessage: "Ocorreu um erro ao processar sua solicitação, tente novamente mais tarde.",
		createNew: "Criar oferta",
		response: "Responder questionário",
	},
};
