import { CategoryType } from "api/CategoriesAPI/urls";
import { Image } from "elements/HeroImage/typing";
import BusinessProfileModel from "models/business-profiles/BusinessProfile";
import CategoryModel from "models/categories/Category";
import CategoriesServiceSingleton from "services/categories/CategoriesService";
import TranslationsServiceSingleton from "services/translations/TranslationsService";

import { URL_HTTPS, URL_VINGLET_IMAGE } from "constants/URLs";
import { StringUtils } from "utils/StringUtils";
import { PageDirectoryUtils } from "@utils";
import {Locales} from "@libs";

const DEFAULT_IMAGE = "/default-image.png";
const MAXIMUM_AMOUNT_STARS = 5;

export enum MarketplaceType {
	PRODUCT = "PRODUCT",
	SERVICE = "SERVICE",
}

export enum MarketplaceOfferType {
	VALIDATE = "VALIDATE",
	SELL = "SELL",
}

export interface Marketplace {
	id: number;
	slug: string;
	owner: BusinessProfileModel;
	title: string;
	images: Image[];
	regions: CategoryModel[];
	sectors: CategoryModel[];
	description: string;
	descriptionWarranty: string;
	descriptionShipping: string;
	descriptionWhatDoYouOffer: string;
	type: MarketplaceType;
	offerType: MarketplaceOfferType;
	quantity: number;
	pvp: number;
	discount: number;
	externalURL: string;
	validationURL: string;
	stars: number;
	published: boolean;
	isMine: boolean;
}

class MarketplaceModel implements Marketplace {
	id: number;
	slug: string;
	owner: BusinessProfileModel;
	title: string;
	images: Image[];
	regions: CategoryModel[];
	sectors: CategoryModel[];
	description: string;
	descriptionWarranty: string;
	descriptionShipping: string;
	descriptionWhatDoYouOffer: string;
	type: MarketplaceType;
	offerType: MarketplaceOfferType;
	quantity: number;
	pvp: number;
	discount: number;
	externalURL: string;
	validationURL: string;
	stars: number;
	published: boolean;
	isMine: boolean;

	constructor(
		id: number,
		slug: string,
		owner: BusinessProfileModel,
		title: string,
		images: Image[],
		regions: CategoryModel[],
		sectors: CategoryModel[],
		description: string,
		descriptionWarranty: string,
		descriptionShipping: string,
		descriptionWhatDoYouOffer: string,
		type: MarketplaceType,
		offerType: MarketplaceOfferType,
		quantity: number,
		pvp: number,
		discount: number,
		externalURL: string,
		validationURL: string,
		stars: number,
		published: boolean,
		isMine: boolean,
	) {
		this.id = id;
		this.slug = slug;
		this.owner = owner;
		this.title = StringUtils.capitalize(title);
		this.images = images;
		this.regions = regions;
		this.sectors = sectors;
		this.description = description;
		this.descriptionWarranty = descriptionWarranty;
		this.descriptionShipping = descriptionShipping;
		this.descriptionWhatDoYouOffer = descriptionWhatDoYouOffer;
		this.type = type;
		this.offerType = offerType;
		this.quantity = quantity;
		this.pvp = pvp;
		this.stars = stars;
		this.discount = discount;

		if (URL_HTTPS !== externalURL.substr(0, URL_HTTPS.length)) {
			externalURL = URL_HTTPS + externalURL;
		}

		if (URL_HTTPS !== validationURL.substr(0, URL_HTTPS.length)) {
			validationURL = URL_HTTPS + validationURL;
		}

		this.validationURL = validationURL;
		this.externalURL = externalURL;
		this.published = published;
		this.isMine = isMine;
	}

	getInternalURL = (locale: Locales) => {
		return PageDirectoryUtils.ROUTE_MARKETPLACE_WITH_LOCALE(locale) + `/${this.slug}`;
	};

	getFormattedImages = (): Image[] => {
		if (this.images && this.images.length > 0) {
			return this.images.map((image) => ({
				src: URL_VINGLET_IMAGE + image.src,
			}));
		}

		return [
			{
				src: DEFAULT_IMAGE,
			},
		];
	};

	getPVPWithDiscount = (): number => {
		return this.pvp - (this.pvp * this.discount) / 100;
	};

	getFormattedPVP = (): string => {
		return this.pvp.toLocaleString("en-US", {
			style: "currency",
			currency: "USD",
		});
	};

	getFormattedPVPWithDiscount = (): string => {
		const pvpWithDiscount = this.getPVPWithDiscount();

		return pvpWithDiscount.toLocaleString("en-US", {
			style: "currency",
			currency: "USD",
		});
	};

	starUp = (stars: number) => {
		if (stars > MAXIMUM_AMOUNT_STARS) {
			this.stars = MAXIMUM_AMOUNT_STARS;
		}

		this.stars = stars;
	};

	exportToAPIRequest = () => {
		const images = this.images.map((image) => image.src).filter((image) => image) as string[];
		const regions = this.regions.map((region) => region.id).filter((region) => region) as number[];
		const sectors = this.sectors.map((sector) => sector.id).filter((sector) => sector) as number[];

		return {
			title: this.title,
			slug: StringUtils.convertStringToCorrectSlug(this.title),
			offer: this.offerType,
			type: this.type,
			description: this.description,
			warranty: this.descriptionWarranty,
			extra: this.descriptionWhatDoYouOffer,
			description_shipping: this.descriptionShipping,
			url: this.externalURL,
			formurl: this.validationURL,
			quantity: this.quantity,
			price: this.pvp,
			discount_percentage: this.discount,
			profile_id: this.owner.info.id,
			published: this.published,
			images,
			regions,
			sectors,
		};
	};

	static generateFromAPI(data: any = {}) {
		const { market: marketsTranslations } = TranslationsServiceSingleton.getFeatureTranslations(["market"]);
		const { region: regionsTranslations } = TranslationsServiceSingleton.getWorldTranslations(["region"]);
		const categoriesList = CategoriesServiceSingleton.getList();
		const regionsCategories = categoriesList.filterByIdsAndCategoryType(
			data.regions ? data.regions.map((item: any) => item.id) : [],
			CategoryType.REGION,
		) as CategoryModel[];
		regionsCategories.forEach((category) => {
			category.setText(regionsTranslations.items[category.key]);
		});
		const sectorsCategories = categoriesList.filterByIdsAndCategoryType(
			data.sectors ? data.sectors.map((item: any) => item.id) : [],
			CategoryType.SECTOR,
		) as CategoryModel[];
		sectorsCategories.forEach((category) => {
			category.setText(marketsTranslations.items[category.key]);
		});
		const images =
			data.images && Array.isArray(data.images)
				? data.images.map((img: string): Image => {
						return {
							src: img,
							alt: data.title,
						};
				  })
				: [];

		return new MarketplaceModel(
			data.id,
			data.slug + "-" + data.id,
			data.profile ? BusinessProfileModel.generateFromAPI(data.profile) : BusinessProfileModel.generateEmpty(),
			data.title,
			images,
			regionsCategories,
			sectorsCategories,
			data.description,
			data.warranty,
			data.description_shipping,
			data.extra,
			data.type as MarketplaceType,
			data.offer as MarketplaceOfferType,
			data.quantity,
			data.price,
			data.discount_percentage,
			data.url,
			data.formurl || "",
			0,
			data.published,
			data.is_mine,
		);
	}

	static generateEmpty() {
		return new MarketplaceModel(
			0,
			"",
			BusinessProfileModel.generateEmpty(),
			"",
			[],
			[],
			[],
			"",
			"",
			"",
			"",
			MarketplaceType.PRODUCT,
			MarketplaceOfferType.VALIDATE,
			0,
			0,
			0,
			"",
			"",
			0,
			false,
			false,
		);
	}

	static generateFromObject(elearningObject: Marketplace) {
		return new MarketplaceModel(
			elearningObject.id,
			elearningObject.slug,
			elearningObject.owner,
			elearningObject.title,
			elearningObject.images,
			elearningObject.regions,
			elearningObject.sectors,
			elearningObject.description,
			elearningObject.descriptionWarranty,
			elearningObject.descriptionShipping,
			elearningObject.descriptionWhatDoYouOffer,
			elearningObject.type,
			elearningObject.offerType,
			elearningObject.quantity,
			elearningObject.pvp,
			elearningObject.discount,
			elearningObject.externalURL,
			elearningObject.validationURL,
			elearningObject.stars,
			elearningObject.published,
			elearningObject.isMine,
		);
	}
}

export default MarketplaceModel;
