import * as React from "react";
import dynamic from "next/dynamic";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";

import { BrowserUtils } from "@utils";

import InternalFormTextareaProps from "./typing";

import "react-quill/dist/quill.snow.css";

const ReactQuill = dynamic(() => import("react-quill"), {
	ssr: false,
});

const InternalFormTextarea = (props: InternalFormTextareaProps) => {
	const { isEditable, value, placeholder, onChange, displayWithoutScrolling, displayWithoutPadding } = props;
	const classNameForEditableTextarea = isEditable ? " InternalFormTextarea-editable" : "";
	const classNameForDisplayingWithoutScrolling = displayWithoutScrolling
		? " InternalFormTextarea-without-scrolling"
		: "";
	const classNameForDisplayingWithoutPadding = displayWithoutPadding ? " InternalFormTextarea-without-padding" : "";

	if (BrowserUtils.isRunningOnBrowser()) {
		return (
			<React.Suspense
				fallback={
					<Form.TextArea
						value={value}
						placeholder={placeholder}
					/>
				}
			>
				<ReactQuill
					className={`InternalFormTextarea${classNameForEditableTextarea}${classNameForDisplayingWithoutScrolling}${classNameForDisplayingWithoutPadding}`}
					theme="snow"
					readOnly={!isEditable}
					modules={{
						toolbar: Boolean(isEditable),
					}}
					value={String(value)}
					placeholder={placeholder}
					onChange={onChange}
				/>
			</React.Suspense>
		);
	} else {
		return (
			<Form.TextArea
				value={value}
				placeholder={placeholder}
			/>
		);
	}
};

export default InternalFormTextarea;
