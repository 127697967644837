import { DimmeableImage } from "elements/DimmeableImage/DimmeableImage";
import { Image } from "elements/HeroImage/typing";
import { ImagePicker } from "elements/ImagePicker/ImagePicker";
import * as React from "react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import { ImagesPickersProps } from "./typing";
import { URL_VINGLET_IMAGE } from "constants/URLs";

const DEFAULT_IMAGE = "/default-image.png";

const fillImages = (images: Image[], maxImagesToBeSelected: number) => {
	const responseImages = [...images];

	for (let i = images.length; i < maxImagesToBeSelected; i++) {
		responseImages[i] = {
			src: DEFAULT_IMAGE,
			alt: "Default Image",
		};
	}

	return responseImages;
};

const ImagesPickers = (props: ImagesPickersProps) => {
	const { images, maxImagesToBeSelected, dimmeable, imagePickerProps, onChange } = props;
	const onChangeImage = (index: number) => (fileName: string) => {
		images[index] = {
			src: fileName,
		};

		onChange(
			images.map((image) => ({
				...image,
				src: image.src.replace(URL_VINGLET_IMAGE, ""),
			})),
		);
	};
	const fullImages = fillImages(images, Number(maxImagesToBeSelected));

	return (
		<Grid
			className="ImagesPickers"
			columns={maxImagesToBeSelected}
			stackable
		>
			{fullImages.map((image, index) => {
				let imagePicker = (
					<ImagePicker
						{...imagePickerProps}
						image={{
							src: image.src,
							alt: image.alt,
						}}
						onChange={onChangeImage(index)}
					/>
				);

				if (dimmeable) {
					imagePicker = (
						<DimmeableImage
							fluid
							src={image.src}
							content={imagePicker}
						/>
					);
				}

				//TODO-> Change the way that we're setting keys -> Bad performance
				return <Grid.Column key={index}> {imagePicker} </Grid.Column>;
			})}
		</Grid>
	);
};

export default ImagesPickers;
